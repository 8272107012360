@font-face {
    font-family: Lato;
    src: url(../font/Lato-Regular.ttf);
}
@font-face {
    font-family: Raleway;
    src: url(../font/Raleway-Regular.ttf);
}
@font-face {
    font-family: Lato;
    src: url(../font/Lato-Regular.ttf);
}
@font-face {
    font-family: Poppins;
    src: url(../font/Poppins-Regular.ttf);
}
@font-face {
    font-family: Lato;
    src: url(../font/Lato-Regular.ttf);
}
@font-face {
    font-family: Raleway;
    src: url(../font/Raleway-Regular.ttf);
}
@font-face {
    font-family: Lato;
    src: url(../font/Lato-Regular.ttf);
}
@font-face {
    font-family: Poppins;
    src: url(../font/Poppins-Regular.ttf);
}


