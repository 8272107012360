@font-face {
    font-family: Lato;
    src: url(../font/Lato-Regular.ttf);
}
@font-face {
    font-family: Raleway;
    src: url(../font/Raleway-Regular.ttf);
}
@font-face {
    font-family: Lato;
    src: url(../font/Lato-Regular.ttf);
}
@font-face {
    font-family: Poppins;
    src: url(../font/Poppins-Regular.ttf);
}
@font-face {
    font-family: Lato;
    src: url(../font/Lato-Regular.ttf);
}
@font-face {
    font-family: Raleway;
    src: url(../font/Raleway-Regular.ttf);
}
@font-face {
    font-family: Lato;
    src: url(../font/Lato-Regular.ttf);
}
@font-face {
    font-family: Poppins;
    src: url(../font/Poppins-Regular.ttf);
}

#container_main_confirm {
    min-width: 100%;
    min-height: 100%;
    box-sizing: border-box;
}

.container_wrapper_confirm {
    min-width: 100%;
    min-height: 100vh;
    display: flex;
}

.form_container_confirm {
    position: relative;
    width: 50%;
    height: 100%;
    background: #ffffff;
    padding: 40px 70px;
}

.image_container_loan {
    width: 50%;
    height: 100%;
    background: url(../images/main_img.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.form_container_header_confirm {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.form_container_header_confirm {
    h2 {
        font-family: Raleway;
        color: #222460;
        font-size: 20px;
        font-weight: 900;
    }
    img {
        width: 70px;
        height: 70px;
        margin-top: -10px;
    }
}

.intro_text {
    font-family: Raleway;
    color: #22246082;
    font-size: 15px;
    font-weight: 900;
    margin-top: 50px;
}

.form_wrapper {
    position: relative;
    display: flex;
}
.input_container_confirm {
    p {
        font-family: Raleway;
        color: #222460;
        font-size: 15px;
        font-weight: 900;
    }
    input {
        width: 300px;
        height: 30px;
        margin-top: -10px;
        font-family: Raleway;
        outline: #222460;
        border: 0.5px solid #494dc488;
        padding: 5px;
    }
    select {
        width: 310px;
        height: 40px;
        margin-top: -10px;
        font-family: Raleway;
        outline: #222460;
        border: 0.5px solid #494dc488;
        padding: 5px;
    }
    .lastInput {
        width: 680px;
        height: 30px;
        margin-top: -10px;
        font-family: Raleway;
        outline: #222460;
        border: 0.5px solid #494dc488;
        padding: 5px;
    }
}
.move {
    margin-left: 60px;
}

.loan_amount_summary {
    position: relative;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #f4fcff;
    padding: 10px 0px;
    margin-top: 40px;
    margin-bottom: 30px;
}

.loan_amount_summary {
    p {
        font-family: Raleway;
        color: #222460;
        font-size: 15px;
        font-weight: 900;
    }
    span {
        font-family: Raleway;
        color: #222460;
        font-size: 40px;
        font-weight: 900;
    }
}

footer {
    width: 100%;
    height: auto;
    background-color: #161f7c;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0px;
}
footer {
    p {
        color: #ffffff;
        font-family: Raleway;
        font-size: 15px;
    }
}

@media screen and (max-width: 768px) {
    #container_main_confirm {
        min-width: 100%;
        min-height: 100%;
        box-sizing: border-box;
    }

    .container_wrapper_confirm {
        min-width: 100%;
        min-height: 100vh;
        display: flex;
    }
    .form_container_confirm {
        position: relative;
        width: 100% !important;
        background: linear-gradient(0deg, rgba(39, 13, 100, 0.9), rgba(0,0,0, 0.5)),
        url(../images/responsive_image.jpg);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        // background: #000;
        padding: 40px 20px !important;
    }

    .form_container_header_confirm {
        h2 {
            font-family: Raleway;
            color: #ffffff;
            font-size: 20px;
            font-weight: 900;
        }
        img {
            width: 70px;
            height: 70px;
            margin-top: -10px;
        }
    }

    .input_container_confirm {
        p {
            font-family: Raleway;
            color: #ffffff;
            font-size: 15px;
            font-weight: 900;
        }
        input {
            width: 300px;
            height: 30px;
            margin-top: -10px;
            font-family: Raleway;
            outline: #222460;
            border: none;
            padding: 5px;
        }
        select {
            width: 310px;
            height: 40px;
            margin-top: -10px;
            font-family: Raleway;
            outline: #222460;
            border: 0.5px solid #494dc488;
            padding: 5px;
        }
        .lastInput {
            width: 680px;
            height: 30px;
            margin-top: -10px;
            font-family: Raleway;
            outline: #222460;
            border: 0.5px solid #494dc488;
            padding: 5px;
        }
    }

    .image_container_loan {
        width: 50%;
        height: 100%;
        background: url(../images/main_img.png);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        display: none;
    }
}

/* loading css animation  Payout*/
#loading_animation_confirm {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(86, 121, 245, 0.7);
    z-index: 999;
    display: none;
}
.loading_container_confirm {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.loading_container_confirm p {
    color: #fff;
    font-family: Poppins;
    font-size: 22px;
}
