#container_main_loan {
    min-width: 100%;
    min-height: 100%;
    box-sizing: border-box;
    display: none;
    position: fixed;
    z-index: 100;
    padding: 10px 20px;
    background-color: rgba(0, 0, 0, 0.9);
    overflow: overlay;
    overflow-x: hidden;
    // margin: 0 auto;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: rgba(90, 90, 90,-2);
  }
  
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.2);
  }

.container_wrapper_loan {
    width: 95%;
    min-height: 100%;
    display: flex;
    position: absolute;

}

.form_container_loan {
    position: relative;
    width: 50%;
    min-height: auto;
    background: #ffffff;
    padding: 40px 70px;
}

.image_container_loan {
    width: 50%;
    height: auto;
    background: url(../images/main_img.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.form_container_header {
    position: relative;
    width: 100%;
    display: flex;
    // justify-content: space-evenly;
}

.form_container_header {
    h2 {
        font-family: Raleway;
        color: #222460;
        font-size: 20px;
        font-weight: 900;
    }
    img {
        width: 70px;
        height: 70px;
        margin-left: 50%;
        margin-top: -10px;
    }
}

.intro_text {
    margin-top: 50px;
}

.intro_text {
    i {
        color: #328679;
    }
    p {
        font-family: Raleway;
        color: #01010cea;
        font-size: 15px;
        font-weight: 900;
    }
}

.form_wrapper {
    position: relative;
    display: flex;
}
.input_container {
    p {
        font-family: Raleway;
        color: #222460;
        font-size: 15px;
        font-weight: 900;
    }
    input {
        width: 300px;
        height: 30px;
        margin-top: -10px;
        font-family: Raleway;
        outline: #222460;
        border: 1px solid #494dc4;
        padding: 5px;
    }
    select {
        width: 300px;
        height: 40px;
        margin-top: -10px;
        font-family: Raleway;
        outline: #222460;
        border: 1px solid #494dc4;
        padding: 5px;
        color: #000000;
    }
}
.move {
    margin-left: 60px;
}

.origination_fees {
    display: flex;
}

.origination_fees {
    h4 {
        font-family: Raleway;
        color: #222460;
        font-size: 18px;
        font-weight: 900;
    }
}
.payments_info {
    h2 {
        font-family: Raleway;
        color: #242426;
        font-size: 15px;
        font-weight: bold;
    }
}

.payment_container {
    background-color: #f4fcff;
    padding: 10px 0px;
}
.total_amount {
    margin-top: 20px;
    margin-left: 50px;
}
.total_amount {
    p {
        font-family: Raleway;
        color: #069ad2;
        font-size: 15px;
        font-weight: 900;
        // margin-bottom: -1px;
    }
    h1 {
        font-family: Poppins;
        color: #222460;
        font-size: 35px;
        font-weight: 700;
        margin-top: -10px;
    }
    h6 {
        font-family: Poppins;
        color: #222460;
        font-size: 20px;
        font-weight: 700;
        margin-top: -10px;
    }
}
.principal_amt_intrest {
    display: flex;
}

.button_cont_loan {
    display: flex;
    width: 100%;
    position: relative;
    justify-content: space-between;
    margin-top: 20px;
}
.button_cont_loan {
    h2 {
        font-family: Raleway;
        color: #069ad2;
        font-size: 15px;
        font-weight: 900;
        cursor: pointer;
    }
    // button {

    // }
    a {
        color: #ffffff;
        text-decoration: none;
        padding: 10px 40px;
        background-color: #069ad2;
        border: none;
        color: #ffffff;
        font-family: Poppins;
        font-size: 17px;
        border-radius: 30px;
    }
    button {
        color: #ffffff;
        text-decoration: none;
        padding: 10px 40px;
        background-color: #069ad2;
        border: none;
        color: #ffffff;
        font-family: Poppins;
        font-size: 17px;
        border-radius: 30px;
    }

    .accept_btn {
        color: #ffffff;
        text-decoration: none;
        padding: 10px 40px;
        background-color: #069ad2;
        border: none;
        color: #ffffff;
        font-family: Poppins;
        font-size: 17px;
        border-radius: 30px;
    }

    .decline_btn {
        margin-right: 10px;
        background-color: #d20606;
    }
}

.button_cont_accept_decline {
    display: flex;
}

// .image_container_loan {
//     width: 50%;
//     height: auto;
//     background: url(../images/main_img.png);
//     background-position: center;
//     background-size: cover;
//     background-repeat: no-repeat;
//     position: relative;
//     display: none;
// }

footer {
    width: 100%;
    height: auto;
    background-color: #161f7c;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0px;
}
footer {
    p {
        color: #ffffff;
        font-family: Raleway;
        font-size: 15px;
    }
}

@media screen and (max-width: 768px) {
    #container_main_loan {
        padding: 10px;
        background-color: rgba(0, 0, 0, 0.9);
        overflow: auto;
    }

    .container_wrapper_loan {
        width: 100%;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        position: absolute;
    }

    .form_container_loan {
        position: relative;
        width: 90% !important;
        background: linear-gradient(0deg, rgba(39, 13, 100, 0.9), rgba(0,0,0, 0.5)),
        url(../images/responsive3.jpg);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        // background: #000;
        padding: 40px 20px !important;
    }

    .form_container_header {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .form_container_header {
        h2 {
            font-family: Raleway;
            color: #ffffff;
            font-size: 20px;
            font-weight: 900;
        }
        img {
            width: 50px;
            height: 50px;
            margin-top: 10px;
        }
    }

    .intro_text {
        i {
            color: #1a8611;
        }
        p {
            font-family: Raleway;
            // color: #01010cea;
            color: #ffffff;
            font-size: 15px;
            font-weight: 900;
        }
    }

    .input_container {
        p {
            font-family: Raleway;
            color: #ffffff;
            font-size: 15px;
            font-weight: 900;
        }
        input {
            width: 300px;
            height: 30px;
            margin-top: -10px;
            font-family: Raleway;
            outline: #222460;
            border: none !important;
            padding: 5px;
        }
        select {
            width: 310px;
            height: 40px;
            margin-top: -10px;
            font-family: Raleway;
            outline: #222460;
            border: none !important;
            padding: 5px;
            color: #000000;
        }
    }

    .origination_fees {
        display: flex;
        flex-direction: column;
    }
    .origination_fees {
        h4 {
            font-family: Raleway;
            color: #ffffffce;
            font-size: 15px;
            font-weight: 900;
        }
    }
    .payments_info {
        h2 {
            font-family: Raleway;
            color: #ffffff;
            font-size: 15px;
            font-weight: bold;
        }
    }

    .payment_container {
        background-color: #f4fcff;
        padding: 10px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: auto;
    }
    .total_amount {
        margin-top: 20px;
        margin-left: 0px;
    }

    .image_container_loan {
        width: 50%;
        height: auto;
        background: url(../images/main_img.png);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        display: none;
    }
}

// @media screen and (device-width: 375px) {
//     footer {
//         width: 100%;
//         height: 100px;
//         background-color: #161f7c;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         padding: 16px 0px;
//         position: absolute;
//         margin-top: 200px;
//     }
// }

/* loading css animation  Payout*/
#loading_animation_payout {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(86, 121, 245, 0.7);
    z-index: 999;
    display: none;
}
.loading_container_payout {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.loading_container_payout p {
    color: #fff;
    font-family: Poppins;
    font-size: 22px;
}


#testing {
    font-family: Poppins;
    font-size: 22px;
}