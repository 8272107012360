@font-face {
    font-family: Lato;
    src: url(../font/Lato-Regular.ttf);
}
@font-face {
    font-family: Raleway;
    src: url(../font/Raleway-Regular.ttf);
}
@font-face {
    font-family: Lato;
    src: url(../font/Lato-Regular.ttf);
}
@font-face {
    font-family: Poppins;
    src: url(../font/Poppins-Regular.ttf);
}
@font-face {
    font-family: Lato;
    src: url(../font/Lato-Regular.ttf);
}
@font-face {
    font-family: Raleway;
    src: url(../font/Raleway-Regular.ttf);
}
@font-face {
    font-family: Lato;
    src: url(../font/Lato-Regular.ttf);
}
@font-face {
    font-family: Poppins;
    src: url(../font/Poppins-Regular.ttf);
}

#container_main_disbursed {
    min-width: 100%;
    min-height: 100%;
    box-sizing: border-box;
}

.container_wrapper_disbursed {
    min-width: 100%;
    min-height: 100vh;
    display: flex;
}

.form_container_disbursed {
    position: relative;
    width: 50%;
    background: #ffffff;
    padding: 40px 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.loan_disbursed_title {
    text-align: center;
    padding: 0px 170px;
}
.loan_disbursed_title {
    h2 {
        font-family: Raleway;
        color: #222460;
        font-size: 20px;
        font-weight: 900;
    }
    p {
        font-family: Raleway;
        color: #22246082;
        font-size: 14px;
        font-weight: 900;
        margin-top: 20px;
        line-height: 25px;
    }
}

.image_container_disbursed {
    width: 50%;
    height: auto;
    background: url(../images/main_img.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.sendmsg_whatsapp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
.sendmsg_whatsapp {
    p {
        font-family: Raleway;
        color: #222460;
        font-size: 15px;
        font-weight: 900;
    }
}

footer {
    width: 100%;
    height: 100px;
    background-color: #161f7c;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0px;
    position: relative;
}
footer {
    p {
        color: #ffffff;
        font-family: Raleway;
        font-size: 15px;
    }
}

@media screen and (max-width: 768px) {
    #container_main_disbursed {
        min-width: 100%;
        min-height: 100%;
        box-sizing: border-box;
    }

    .container_wrapper_disbursed {
        min-width: 100%;
        min-height: 100vh;
        display: flex;
    }
    .form_container_disbursed {
        position: relative;
        width: 100% !important;
        background: linear-gradient(0deg, rgba(39, 13, 100, 0.9), rgba(0,0,0, 0.5)),
        url(../images/responsive2.jpg);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        // background: #000;
        padding: 40px 20px !important;
    }
    .image_container_disbursed {
        width: 50%;
        height: auto;
        background: url(../images/main_img.png);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        display: none;
    }
    .loan_disbursed_title {
        text-align: center;
        padding: 0px 10px;
    }
    .loan_disbursed_title {
        h2 {
            font-family: Raleway;
            color: #ffffff;
            font-size: 20px;
            font-weight: 900;
        }
        p {
            font-family: Raleway;
            color: #ffffff;
            font-size: 14px;
            font-weight: 900;
            margin-top: 20px;
            line-height: 25px;
        }
    }
    .sendmsg_whatsapp {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
    }
    .sendmsg_whatsapp {
        p {
            font-family: Raleway;
            color: #ffffff;
            font-size: 15px;
            font-weight: 900;
            text-align: center;
        }
    }
}
