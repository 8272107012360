@font-face {
    font-family: Lato;
    src: url(../font/Lato-Regular.ttf);
}

@font-face {
    font-family: Raleway;
    src: url(../font/Raleway-Regular.ttf);
}

@font-face {
    font-family: Lato;
    src: url(../font/Lato-Regular.ttf);
}

@font-face {
    font-family: Poppins;
    src: url(../font/Poppins-Regular.ttf);
}

@font-face {
    font-family: Lato;
    src: url(../font/Lato-Regular.ttf);
}

@font-face {
    font-family: Raleway;
    src: url(../font/Raleway-Regular.ttf);
}

@font-face {
    font-family: Lato;
    src: url(../font/Lato-Regular.ttf);
}

@font-face {
    font-family: Poppins;
    src: url(../font/Poppins-Regular.ttf);
}

#container_main_support {
    min-width: 100%;
    min-height: 100vh;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
}

.container_wrapper_support {
    width: 100%;
    min-height: 100%;
    display: flex;
}

.form_container_support {
    position: relative;
    width: 40%;
    //   height: 100%;
    background: #ffffff;
    padding: 40px 70px;
}

.image_container_support {
    width: 60%;
    //   height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url(../images/support_img.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.form_container_header_support {
    position: relative;
    width: 100%;
    margin-top: 20px;
}

.form_container_header_support {
    h1 {
        font-family: Raleway;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        color: #0072b9;
    }
    p {
        font-family: Raleway;
        color: #222460;
        font-size: 14px;
        font-weight: 900;
    }
    img {
        width: 70px;
        height: 70px;
        margin-top: -10px;
    }
}

.intro_text {
    font-family: Raleway;
    color: #22246082;
    font-size: 15px;
    font-weight: 900;
    margin-top: 50px;
}

.form_wrapper_support {
    position: relative;
    display: flex;
}

.input_container_support {
    p {
        font-family: Raleway;
        color: #222460;
        font-size: 15px;
        font-weight: 900;
    }
    input {
        width: 300px;
        height: 30px;
        margin-top: -10px;
        font-family: Raleway;
        outline: #222460;
        border: 0.5px solid #494dc488;
        padding: 5px;
    }
    select {
        width: 310px;
        height: 40px;
        margin-top: -10px;
        font-family: Raleway;
        outline: #222460;
        border: 0.5px solid #494dc488;
        padding: 5px;
    }
    .lastInput {
        width: 680px;
        height: 30px;
        margin-top: -10px;
        font-family: Raleway;
        outline: #222460;
        border: 0.5px solid #494dc488;
        padding: 5px;
    }
}

.message_container_support {
    margin-top: 30px;
    p {
        font-family: Raleway;
        color: #222460;
        font-size: 15px;
        font-weight: 900;
    }
    textarea {
        width: 100%;
        height: 200px;
        position: relative;
        outline: #222460;
        border: 0.5px solid #494dc488;
        margin-top: -10px;
    }
    button {
        color: #ffffff;
        background: #0072b9;
        box-shadow: 0px 4px 4px rgba(196, 196, 196, 0.08);
        border-radius: 5px;
        border: none;
        padding: 10px 20px;
        font-family: Raleway;
        font-size: 15px;
        margin-top: 20px;
    }
}

.move__ {
    margin-left: 30px;
}

.loan_amount_summary {
    position: relative;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #f4fcff;
    padding: 10px 0px;
    margin-top: 40px;
    margin-bottom: 30px;
}

.loan_amount_summary {
    p {
        font-family: Raleway;
        color: #222460;
        font-size: 15px;
        font-weight: 900;
    }
    span {
        font-family: Raleway;
        color: #222460;
        font-size: 40px;
        font-weight: 900;
    }
}

footer {
    width: 100%;
    height: auto;
    background-color: #161f7c;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0px;
}

footer {
    p {
        color: #ffffff;
        font-family: Raleway;
        font-size: 15px;
    }
}

@media screen and (max-width: 768px) {
    #container_main_confirm {
        min-width: 100%;
        min-height: 100%;
        box-sizing: border-box;
    }
    .container_wrapper_confirm {
        min-width: 100%;
        min-height: 100vh;
        display: flex;
    }
    .form_container_confirm {
        position: relative;
        width: 100% !important;
        background: linear-gradient(0deg, rgba(39, 13, 100, 0.9), rgba(0, 0, 0, 0.5)),
            url(../images/responsive_image.jpg);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        // background: #000;
        padding: 40px 20px !important;
    }
    .form_container_header_confirm {
        h2 {
            font-family: Raleway;
            color: #ffffff;
            font-size: 20px;
            font-weight: 900;
        }
        img {
            width: 70px;
            height: 70px;
            margin-top: -10px;
        }
    }
    .input_container_confirm {
        p {
            font-family: Raleway;
            color: #ffffff;
            font-size: 15px;
            font-weight: 900;
        }
        input {
            width: 300px;
            height: 30px;
            margin-top: -10px;
            font-family: Raleway;
            outline: #222460;
            border: none;
            padding: 5px;
        }
        select {
            width: 310px;
            height: 40px;
            margin-top: -10px;
            font-family: Raleway;
            outline: #222460;
            border: 0.5px solid #494dc488;
            padding: 5px;
        }
        .lastInput {
            width: 680px;
            height: 30px;
            margin-top: -10px;
            font-family: Raleway;
            outline: #222460;
            border: 0.5px solid #494dc488;
            padding: 5px;
        }
    }
    .image_container_loan {
        width: 50%;
        height: 100%;
        background: url(../images/main_img.png);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        display: none;
    }
}

/* loading css animation  Payout*/

#loading_animation_confirm {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(86, 121, 245, 0.7);
    z-index: 999;
    display: none;
}

.loading_container_confirm {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.loading_container_confirm p {
    color: #fff;
    font-family: Poppins;
    font-size: 22px;
}

.contactus_section {
    margin-top: 40px;
    h1 {
        font-family: Raleway;
        font-style: normal;
        font-weight: 900;
        font-size: 20px;
        line-height: 38px;
        /* identical to box height */
        color: #0072b9;
    }
}

.contactus_details {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.contact_det1 {
    img {
        width: 300px;
        height: 300px;
    }
}

.contact_det1,
.contact_det2 {
    p {
        color: #0072b9;
        font-family: Raleway;
        font-size: 12px;
        font-weight: 900;
    }
    a {
        color: #0072b9;
        font-family: Raleway;
        font-size: 12px;
        font-weight: 900;
        margin-top: 20px;
        text-decoration: none;
        margin-left: 5px;
    }
}

.social_icon_contact {
    i {
        color: #0072b9;
        font-size: 20px;
        margin: 10px 20px;
    }
}

@media screen and (max-width: 768px) {
    #container_main {
        min-width: 100%;
        min-height: 100%;
        box-sizing: border-box;
    }
    .container_wrapper_support {
        width: 100%;
        min-height: 100vh;
        display: flex;
        // flex-direction: column;
        // // overflow: auto;
        // position: relative;
    }
    .form_container_support {
        position: relative;
        width: 100% !important;
        background: linear-gradient(0deg, rgba(39, 13, 100, 0.9), rgba(0, 0, 0, 0.5)), url(../images/support_img.jpg);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        padding: 40px 20px !important;
    }
    .form_wrapper_support {
        position: relative;
        display: flex;
        flex-direction: column;
    }
    .image_container_support {
        display: none;
    }
    .move__ {
        margin-left: 0px;
    }
    .form_container_header_support {
        h1 {
            font-family: Raleway;
            font-style: normal;
            font-weight: bold;
            font-size: 30px;
            color: #ffffff;
        }
        p {
            font-family: Raleway;
            color: #ffffff;
            font-size: 14px;
            font-weight: 900;
        }
        img {
            width: 70px;
            height: 70px;
            margin-top: -10px;
        }
    }
    .contactus_section {
        margin-top: 40px;
        h1 {
            font-family: Raleway;
            font-style: normal;
            font-weight: 900;
            font-size: 20px;
            line-height: 38px;
            /* identical to box height */
            color: #ffffff;
        }
    }
    .input_container_support {
        p {
            font-family: Raleway;
            color: #ffffff;
            font-size: 15px;
            font-weight: 900;
        }
        input {
            width: 300px;
            height: 30px;
            margin-top: -10px;
            font-family: Raleway;
            outline: #222460;
            border: none;
            padding: 5px;
        }
        select {
            width: 310px;
            height: 40px;
            margin-top: -10px;
            font-family: Raleway;
            outline: #222460;
            border: 0.5px solid #494dc488;
            padding: 5px;
        }
        .lastInput {
            width: 680px;
            height: 30px;
            margin-top: -10px;
            font-family: Raleway;
            outline: #222460;
            border: 0.5px solid #494dc488;
            padding: 5px;
        }
    }
    .message_container_support {
        margin-top: 30px;
        p {
            font-family: Raleway;
            color: #ffffff;
            font-size: 15px;
            font-weight: 900;
        }
        textarea {
            width: 90%;
            height: 200px;
            position: relative;
            outline: #222460;
            border: none;
            margin-top: -10px;
        }
        button {
            color: #ffffff;
            background: #0072b9;
            box-shadow: 0px 4px 4px rgba(196, 196, 196, 0.08);
            border-radius: 5px;
            border: none;
            padding: 10px 20px;
            font-family: Raleway;
            font-size: 15px;
            margin-top: 20px;
        }
    }
    .contactus_details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: start;
    }
    .contact_det1 {
        img {
            width: 200px;
            height: 200px;
        }
    }
    .contact_det1,
    .contact_det2 {
        p {
            color: #ffffff;
            font-family: Raleway;
            font-size: 12px;
            font-weight: 900;
            margin-top: 20px;
        }
        a {
            color: #ffffff;
            font-family: Raleway;
            font-size: 12px;
            font-weight: 900;
            margin-top: 20px;
            text-decoration: none;
        }
    }
    .social_icon_contact {
        i {
            color: #ffffff;
            font-size: 20px;
            margin: 10px 20px;
        }
    }
    footer p {
        color: #ffffff;
        font-family: Raleway;
        font-size: 15px;
        text-align: center;
    }
}
