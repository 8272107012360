/* for desktop */
// .whatsapp_float {
// 	position: fixed;
// 	width: 50px;
// 	height: 50px;
// 	bottom: 100px;
// 	right: 20px;
// 	background-color: #25d366;
// 	color: #fff;
// 	border-radius: 50px;
// 	text-align: center;
// 	font-size: 25px;
// 	box-shadow: 2px 2px 3px #999;
// 	z-index: 100;
// }

// .whatsapp-icon {
// 	margin-top: 16px;
// }
// /* for mobile */
// @media screen and (max-width: 767px) {
// 	.whatsapp-icon {
// 		margin-top: 10px;
// 	}
// 	.whatsapp_float {
// 		width: 40px;
// 		height: 40px;
// 		bottom: 100px;
// 		right: 20px;
// 		font-size: 22px;
// 	}
// }

// .beamer_defaultBeamerSelector {
// display: none !important;

// }

#menu {
    // width: 10px;
    // height: 10px;
    // position: absolute;
    // left: 50%;
    // top: 50%;
    // margin: -75px 0 0 -75px;
    list-style: none;
    // font-size: 200%;
    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 60px;
    right: 20px;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 25px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.menu-button {
    opacity: 0;
    z-index: -1;
}

.menu-button {
    width: 70px;
    height: 70px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    background: #FFBD80;
    background-size: 100%;
    overflow: hidden;
    text-decoration: none;
    line-height: 150px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

#menu:not(:target) > a:first-of-type,
#menu:target > a:last-of-type {
    opacity: 1;
    z-index: 1;
}

#menu:not(:target) > .icon-plus:before,
#menu:target > .icon-minus:before {
    opacity: 1;
}

.menu-item {
    width: auto;
    height: auto;
    position: absolute;
    left: 55%;
    line-height: 5px;
    top: 20%;
    margin: -50px 0 0 -50px;
    border-radius: 50%;
    background-color: #424242;
    transform: translate(0px, 0px);
    transition: transform 500ms;
    z-index: -2;
    transition: 0.5s;
    padding: 10px;
}

.menu-item:hover {
    opacity: 0.5;
    box-shadow: 0 5px 10px black;
}

.menu-item a {
    color: #fff;
    position: relative;
    top: 30%;
    left: 0;
    text-decoration: none;
}

#menu:target > .menu-item:nth-child(6) {
    transform: rotate(60deg) translateY(-150px) rotate(300deg);
    transition-delay: 0s;
}

#menu:target > .menu-item:nth-child(5) {
    transform: rotate(20deg) translateY(-155px) rotate(-20deg);
    transition-delay: 0.1s;
}

#menu:target > .menu-item:nth-child(3) {
    transform: rotate(-20deg) translateY(-155px) rotate(20deg);
    transition-delay: 0.2s;
}

#menu:target > .menu-item:nth-child(4) {
    transform: rotate(-60deg) translateY(-150px) rotate(60deg);
    transition-delay: 0.3s;
}

.content {
    position: absolute;
    text-align: center;
    margin: -10px 0 0 -30px;
    top: 70%;
    left: 50%;
    font-size: 20px;
}

